import Button from '@/components/button';
import Modal from '@/components/modal';
import PairingForm from '@/components/partials/passenger';
import {useAuth} from '@/stores/auth';
import {useSdk} from '@/stores/sdk';
import {useTunnel} from '@/stores/tunnel';
import {computePhoto} from '@/utils/computePhoto';
import {formatDate} from '@/utils/formatDate';
import {dispatchGAEvent, dispatchGAPage} from '@/utils/googleAnalytics';
import {useNavigate} from '@solidjs/router';
import {Icon} from 'solid-heroicons';
import {user} from 'solid-heroicons/outline';
import {
  createEffect,
  createResource,
  createSignal,
  For,
  on,
  onMount,
  ParentProps,
  Resource,
  ResourceActions,
  Show,
} from 'solid-js';
import Found from "@/components/partials/svdPairing/found";
import {createStore} from "solid-js/store";
import Loader from "@/components/loader";
import {Child, SvdPairing} from "@/lib/sdk";
import {useGlobalStore} from "@/stores";

export function extractFormValues<
	T extends string,
	U extends Record<T, string>,
>(form: HTMLFormElement, properties?: readonly T[]): U {
  const formData = new FormData(form);
  const entries = Object.fromEntries(formData.entries()) as U;

  return Object.entries(entries).reduce((pickedEntries, [key, value]) => {
	if (properties && !properties.includes(key as T)) {
	  return pickedEntries;
	}

	pickedEntries[key] = value;

	return pickedEntries;
  }, {} as U);
}

function findPassenger(passengerId: string, auth: {
  user: {
	rcuId: string;
  };
}, _children: any[]) {
  return passengerId === auth.user.rcuId
	  ? auth.user
	  : _children.find(
		  (children) => children.rcuId === passengerId,
	  );
}

function PassengerChoice(
	props: ParentProps<{
	  parentChildrenActions: ResourceActions<Child[]>,
	  parentChildren: Resource<Child[]>,
	  error: string;
	}>,
) {
  const [sdk] = useSdk();
  const go = useNavigate();
  const [auth, authActions] = useAuth();
  const [tunnel, tunnelActions] = useTunnel();
  const [globalStore, globalStoreActions] = useGlobalStore();

  const [error, setError] = createSignal(props.error || '');
  const [passenger, setPassenger] = createSignal('');
  const [passengerInfo, setPassengerInfos] = createSignal({} as Child | undefined);
  const [showPassengerModalTitle, setShowPassengerModalTitle] = createSignal('Ajouter un voyageur');
  const [showPassengerModal, setShowPassengerModal] = createSignal(false);
  const [showPairingModal, setShowPairingModal] = createSignal(false);
  const [modalTitle, setModalTitle] = createSignal('');
  const [svd, setSvd] = createStore(
	  {
		canPairByCode: false,
		canPairByEmail: false,
		found: true,
		pairingEmail: '',
		message: ''
	  });
  const [awaitPairing, setAwaitPairing] = createSignal(false);
  const [isMainAccount, setIsMainAccount] = createSignal(true);
  const [tryptiqueForm, setTryptiqueForm] = createStore({
	firstName: '',
	lastName: '',
	birthDate: '',
  });

  const isCykleo = () => tunnel.product.variants.some((variant) => variant.providerId === "CYKLEO");
  const isThales = () => tunnel.product.variants.some((variant) => variant.providerId === "THALES");

  const hasCykleoOption = () => tunnel.options.length && tunnel.options.some((option) => option.variants.some((variant) => variant.providerId === "CYKLEO"));

  const containCykleo = () => isCykleo() || hasCykleoOption();

  createEffect(() => {
	return setError(props.error);
  });

  createEffect(on(
	  () => props.parentChildren(),
	  () => {
		if (props.parentChildren.loading) {
		  return;
		}

		refreshWaitingChildren();
	  }
  ))

  const photo = () => computePhoto(auth.user);

  function refreshWaitingChildren() {

	if (!props.parentChildren()) return

	const childrenWaitingForValidation = props.parentChildren().filter(child => child.associationStatus === 'WAITING_VALIDATION')

	if (Boolean(childrenWaitingForValidation.length)) {
	  const intervalId = setInterval(async () => {

		if (!location.pathname.includes('passenger') || !childrenWaitingForValidation.length) {
		  return clearInterval(intervalId)
		}

		for (const child of childrenWaitingForValidation) {
		  const childrenInformation = await sdk.auth.customer(auth.token, {rcuId: child.rcuId});

		  if (childrenInformation.associationStatus === 'PAIRED') {
			props.parentChildrenActions.mutate(prevChildren => {
			  return prevChildren.map((child) =>
				  child.rcuId === childrenInformation.rcuId ? childrenInformation : child
			  );
			});
		  }
		}

	  }, 10 * 1000);
	}
  }

  async function validatePassengerProfiles(
	  passengerId: string,
	  _children = props.parentChildren(),
  ) {
	const neededProfiles = tunnel.product.profiles;

	const passenger = findPassenger(passengerId, auth, _children);

	if (!passenger) {
	  return false;
	}

	// If the user doesn't have cards then he can potentially order the profile
	// with its new card
	const hasAtLeastOneElligibleProfile =
		neededProfiles?.some((profile) => {
		  if (!passenger) {
			return false;
		  }

		  const hasExplicitProfile = passenger.profiles?.some(
			  (passengerProfile) => {
				return passengerProfile.profileId === String(profile);
			  },
		  );

		  if (hasExplicitProfile) {
			return true;
		  }

		  const profileAsString = String(profile);
		  const isSolidaire = profileAsString.length === 6;

		  if (!isSolidaire) {
			return false;
		  }

		  const explicitProfile = String(profileAsString.slice(0, 3));
		  const implicitProfile = String(profileAsString.slice(3));


		  //TODO: Check type of different profiles
		  const hasExplicitProfileForSolidaire = passenger.profiles.some(
			  (passengerProfile) => passengerProfile.profileId === explicitProfile,
		  );
		  const hasImplicitProfileForSolidaire = passenger.profiles.some(
			  (passengerProfile) => passengerProfile.profileId === implicitProfile,
		  );

		  return hasExplicitProfileForSolidaire && hasImplicitProfileForSolidaire;
		}) ?? true;

	if (!hasAtLeastOneElligibleProfile && !tunnel.profile?.isSaleable) {
	  if (tunnel.profile?.externalLink) {
		window.open(tunnel.profile.externalLink, '_blank', 'noopener');
	  }

	  setError("Vous n'êtes pas éligible pour cette offre");

	  return false;
	}

	if (!hasAtLeastOneElligibleProfile) {
	  tunnelActions.set({
		needsAdditionalData: true,
		needsToOrderProfile: true,
	  });
	} else {
	  if (
		  tunnel.needsToValidateTemporalValidity ||
		  tunnel.product.isOrganizationLink
	  ) {
		tunnelActions.set({needsToOrderProfile: false});
	  } else {
		tunnelActions.set({
		  needsAdditionalData: false,
		  needsToOrderProfile: false,
		});
	  }
	}

	return true;
  }

  const [loading, setLoading] = createSignal(false);

  async function checkSvdPairing(rcuId: string, isProductVariantSubscriberOnly = false) {

	const checkSvdPairing = rcuId === auth.user.rcuId ? await sdk.svd.checkPairing() : await sdk.svd.checkPairing(rcuId);

	setSvd(checkSvdPairing);

	if (!svd.found) {

	  if (isProductVariantSubscriberOnly) {
		const subscriptions = await sdk.customer.subscriptions();

		return subscriptions.some(sub => sub.providerId === 'AIRWEB' && sub.status === 'ACTIVE')
	  }

	  setPassengerInfos(findPassenger(rcuId, auth, props.parentChildren()));
	  setShowPassengerModalTitle('Mon dossier client TBM')
	  setShowPassengerModal(true);
	  return false;
	}

	const wording = {
	  me: {
		'EMAIL_MISMATCH': 'Nous vous avons trouvé !',
		'NO_SVD_EMAIL': 'Nous vous avons trouvé !'
	  },
	  other: {
		'EMAIL_MISMATCH': 'Nous avons trouvé le voyageur !',
		'NO_SVD_EMAIL': 'Confirmer le voyageur pour continuer !'
	  }

	}

	if (svd.message === 'SVD_CUSTOMER_ACCOUNT_FOUND') {
	  if (rcuId === auth.user.rcuId) {
		const user = await sdk.auth.customer(auth.token);
		authActions.set({user});
	  } else {
		props.parentChildrenActions.refetch()
	  }

	  return true;
	}

	if (svd.message === 'EMAIL_MISMATCH') {
	  setModalTitle('Nous vous avons trouvé ! ');
	}

	if (svd.message === 'NO_SVD_EMAIL') {
	  setModalTitle('Nous vous avons trouvé ! ');
	}

	setShowPairingModal(true)
	return false
  }


  async function handleSubmit(event: Event) {
	try {

	  event.preventDefault();

	  setLoading(true);
	  setError('');

	  const form = event.target as HTMLFormElement;
	  const values = extractFormValues(form, ['passenger']);

	  // TODO: Maybe handle error here?
	  if (!values.passenger) {
		return;
	  }


	  if (values.passenger === 'new') {
		setPassengerInfos(undefined);
		setShowPassengerModal(true);
		return;
	  }

	  const passenger = findPassenger(values.passenger, auth, props.parentChildren());
	  setPassengerInfos(passenger);

	  const isProductVariantSubscriberOnly = tunnel.product.variants.some(variant => variant.subscribersOnly)

	  if ((isThales() || isProductVariantSubscriberOnly) && !passenger.technicalAccounts.find(account => account.application === 'SVD')) {
		const isPaired = await checkSvdPairing(passenger.rcuId, isProductVariantSubscriberOnly)
		if (!isPaired) {
		  if (isProductVariantSubscriberOnly) {
			setError("Vous n'êtes pas éligible pour cette offre")
		  }
		  return;
		}
	  }

	  const canGoNext = await validatePassengerProfiles(values.passenger);

	  // Bail early if the user doesn't have the required profile
	  // and can't buy it
	  if (!canGoNext) {
		return;
	  }

	  if (hasCykleoOption()) {
		const subscriptions = await sdk.customer.subscriptions();

		if (subscriptions.some(sub => sub.providerId === 'CYKLEO' && sub.status === 'ACTIVE')) {
		  setError("Vous n'êtes pas éligible pour cette offre");
		  return;
		}
	  }

	  if (containCykleo()) {

		const [billingAddress] = await sdk.customer.addresses();

		if ((!auth.user.mobileNumber && !auth.user.phoneNumber) || !billingAddress) {
		  setShowPassengerModalTitle('Confirmation de mes informations personnelles')
		  setShowPassengerModal(true);
		  return;
		}

		try {
		  await sdk.customer.pairingCykleo();
		} catch (e) {
		  setError('Une erreur est survenue, veuillez réessayer.')
		  props.parentChildrenActions.refetch()
		}
	  }

	  if (isProductVariantSubscriberOnly) {
		const subscriptions = await sdk.customer.subscriptions();

		if (!subscriptions.some(sub => (sub.providerId === 'AIRWEB' || sub.providerId === 'THALES') && sub.status === 'ACTIVE')) {
		  setError("Vous n'êtes pas éligible pour cette offre");
		  return
		}
	  }

	  const refreshPassenger = findPassenger(values.passenger, auth, props.parentChildren());

	  tunnelActions.set('passenger', refreshPassenger.thalesCustomerId);
	  tunnelActions.set('passengerRcuId', refreshPassenger.rcuId);

	  tunnelActions.set('passengerInfos', refreshPassenger)


	  dispatchGAEvent('select_traveler_info', null, auth.user, {
		opt: {
		  who:
			  values.passenger === auth.user.rcuId
				  ? 'vous'
				  : 'un autre voyageur',
		  isc: 'oui',
		},
	  });

	  if (tunnel.product.cykleoProductType === 1 && !tunnel.product.isYearlySubscription) {
		globalStoreActions.addToCart({
		  product: tunnel.product,
		  quantity: tunnel.quantity,
		  delivery: tunnel.delivery
		}, tunnel.passenger);

		return go(`/products/${tunnel.product.id}?cart-open=true&reset-tunnel=true`);
	  }

	  if (isCykleo()) {

		const precheckResult = await sdk.order.precheck({rcuUserId: tunnel.passengerRcuId, productId: tunnel.product.id});
		tunnelActions.set('precheckResult', precheckResult)

		if (precheckResult.valid) {

		  if (precheckResult.neededProofDocuments.length) return go(`/associate/card`);

		  return tunnel.needsAdditionalData ? go(`/associate/data`) : go('/associate/terms');

		} else {
		  if (precheckResult.code === "301") {
			setError("Vous avez déjà un abonnement vélo en cours.");
		  } else {
			setError("Vous n'êtes pas éligible pour cette offre");
		  }
		  return;
		}
	  }

	  return go(`/associate/card`);
	} finally {
	  setLoading(false);
	}
  }

  function handleClose() {
	props.parentChildrenActions.refetch()

	setShowPassengerModal(false);
	setSvd({
	  canPairByCode: false,
	  canPairByEmail: false,
	  found: true,
	  pairingEmail: '',
	  message: ''
	});
  }

  function handleFoundClose() {
	props.parentChildrenActions.refetch()
	setShowPairingModal(false);
	setShowPassengerModal(false);
  }


  async function handleSearchResultForm(userId: string, isMainAccount?: boolean, rcuAssociation?: SvdPairing, searchValuesFromForm?) {

	if (rcuAssociation && rcuAssociation.found && !rcuAssociation.canPairByCode && !rcuAssociation.canPairByEmail) {
	  setShowPassengerModal(false);
	  setShowPairingModal(false);

	  setError("Vous avez déjà associé ce voyageur.");
	  return
	}

	if (rcuAssociation && rcuAssociation.message === 'AWAITING_VALIDATION') {
	  setShowPassengerModal(false);
	  setShowPairingModal(false);
	  setAwaitPairing(true);
	  refreshWaitingChildren()
	  return;
	}

	if (!isMainAccount && rcuAssociation) {
	  setIsMainAccount(false)
	  setTryptiqueForm(searchValuesFromForm)
	  setSvd(rcuAssociation);

	  if (rcuAssociation.canPairByEmail) {
		setModalTitle('Nous avons trouvé votre voyageur !');
	  } else if (rcuAssociation.canPairByCode) {
		setModalTitle('Confirmer votre voyageur pour continuer !');
	  }

	  setShowPassengerModal(false);
	  setShowPairingModal(true)
	  return false
	}

	if (containCykleo()) {
	  const [billingAddress] = await sdk.customer.addresses();

	  if ((!auth.user.mobileNumber && !auth.user.phoneNumber) || !billingAddress) {
		setShowPassengerModalTitle('Confirmation de mes informations personnelles')
		setShowPassengerModal(true);
		return;
	  }

	  try {
		await sdk.customer.pairingCykleo();
	  } catch (e) {
		setError('Une erreur est survenue, veuillez réessayer.')
		props.parentChildrenActions.refetch()
	  }
	}

	/*if (svdCheckInfo) {
	  setAwaitPairing(false);
	  return;
	}*/
	// This random call is used to fill the local cache with the newly created user...
	// That's actually disgusting but I'll try to think of a better way before anyone else sees this code.
	// Ok thanks mate, good luck with that
	const children = await sdk.customer.children(false);
	props.parentChildrenActions.mutate(children);
	const canGoNext = await validatePassengerProfiles(userId);

	setShowPassengerModal(false);
	setShowPairingModal(false);

	const isProductVariantSubscriberOnly = tunnel.product.variants.some(variant => variant.subscribersOnly)

	if (isProductVariantSubscriberOnly) {
	  const subscriptions = await sdk.customer.subscriptions();


	  if (!subscriptions.some(sub => (sub.providerId === 'AIRWEB' || sub.providerId === 'THALES') && sub.status === 'ACTIVE')) {
		setError("Vous n'êtes pas éligible pour cette offre");
		return
	  }
	}

	// 1421508 / 1995-08-02
	if (!canGoNext) {
	  return;
	}

	const passenger = findPassenger(userId, auth, children);

	tunnelActions.set('passenger', passenger.thalesCustomerId);
	tunnelActions.set('passengerRcuId', passenger.rcuId);
	tunnelActions.set('passengerInfos', passenger)


	if (tunnel.product.cykleoProductType === 1 && !tunnel.product.isYearlySubscription) {
	  globalStoreActions.addToCart({
		product: tunnel.product,
		quantity: tunnel.quantity,
		delivery: tunnel.delivery
	  }, tunnel.passenger);

	  return go(`/products/${tunnel.product.id}?cart-open=true&reset-tunnel=true`);
	}

	if (isCykleo()) {

	  const precheckResult = await sdk.order.precheck({rcuUserId: tunnel.passengerRcuId, productId: tunnel.product.id});
	  tunnelActions.set('precheckResult', precheckResult)

	  if (precheckResult.valid) {

		if (precheckResult.neededProofDocuments.length) return go(`/associate/card`);

		return tunnel.needsAdditionalData ? go(`/associate/data`) : go('/associate/terms');

	  } else {
		if (precheckResult.code === "301") {
		  setError("Vous avez déjà un abonnement vélo en cours.");
		} else {
		  setError("Vous n'êtes pas éligible pour cette offre");
		}
		return;
	  }
	}

	return go(`/associate/card`);
  }

  return (
	  <>
		<h1 class="mt-8 text-center text-xl font-semibold">
		  Sélectionnez un voyageur
		</h1>
		<Show when={!props.parentChildren.loading} fallback={<Loader class="mx-auto mt-8"/>}>
		  <form onSubmit={handleSubmit} class="flex flex-col space-y-6">
			<section class="flex flex-col">
			  <ul class="mt-6 grid gap-4 lg:grid-cols-3">
				<li>
				  <input
					  type="radio"
					  class="peer sr-only"
					  name="passenger"
					  onChange={() => setPassenger(auth.user.rcuId)}
					  value={auth.user.rcuId}
					  checked={passenger() === auth.user.rcuId}
					  required
					  id="parent"
				  />

				  <label
					  for="parent"
					  class="flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 px-6 pt-6 text-center focus:border-primary/50 peer-checked:border-primary"
				  >
					<div
						class="flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-gray-400 text-white">
					  <Show
						  when={photo()}
						  fallback={<Icon path={user} class="h-8"/>}
					  >
						<img
							src={photo()}
							class="h-full w-full object-cover object-center"
						/>
					  </Show>
					</div>

					<span class="mt-6 font-semibold">
                    {auth.user.firstName} {auth.user.lastName}
                  </span>

					<Button class="my-2" variant="primary">
					  Vous
					</Button>
				  </label>
				</li>

				<Show when={awaitPairing()}>
				  <li onClick={() => setError('Le voyageur doit donner son accord en cliquant sur le lien reçu par e-mail.')}>
					<input
						type="radio"
						class="peer sr-only"
						disabled
						name="passenger"
						value="awaitPairing"
						required
						id="awaitPairing"
					/>

					<label
						for="awaitPairing"
						class="flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 p-6 text-center focus:border-primary/50 peer-checked:border-primary peer-disabled:opacity-50 peer-disabled:bg-gray-100 peer-disabled:cursor-not-allowed"
					>
					  <div
						  class="flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-gray-400 text-white">
						<Show
							when={photo()}
							fallback={<Icon path={user} class="h-8"/>}
						>
						  <img
							  src={photo()}
							  class="h-full w-full object-cover object-center"
						  />
						</Show>
					  </div>

					  <span class="mt-6 font-semibold">
					  <Show when={isMainAccount()}
							fallback={
							  <>
								{tryptiqueForm.firstName} {tryptiqueForm.lastName}
							  </>
							}>
						<>
                    		{auth.user.firstName} {auth.user.lastName}
						</>
					  </Show>
				  </span>

					  <Button class="my-2" variant="primary">
						En attente de validation
					  </Button>
					</label>
				  </li>
				</Show>


				<For each={props.parentChildren()}>
				  {(child) => {
					const photo = () => computePhoto(child);

					if (child.associationStatus === 'WAITING_VALIDATION') {
					  return (
						  <li onClick={() => setError('Le voyageur doit donner son accord en cliquant sur le lien reçu par e-mail.')}>
							<input
								type="radio"
								class="peer sr-only"
								disabled
								name="passenger"
								value="awaitPairing"
								required
								id="awaitPairing"
							/>

							<label
								for="awaitPairing"
								class="flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 p-6 text-center focus:border-primary/50 peer-checked:border-primary peer-disabled:opacity-50 peer-disabled:bg-gray-100 peer-disabled:cursor-not-allowed"
							>
							  <div
								  class="flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-gray-400 text-white">
								<Show
									when={photo()}
									fallback={<Icon path={user} class="h-8"/>}
								>
								  <img
									  src={photo()}
									  class="h-full w-full object-cover object-center"
								  />
								</Show>
							  </div>

							  <span class="mt-6 font-semibold">
                    			{child.firstName} {child.lastName}
				  			</span>

							  <Button class="my-2" variant="primary">
								En attente de validation
							  </Button>
							</label>
						  </li>
					  )
					}
					return (
						<li onClick={() => containCykleo() ? setError("Vous ne pouvez pas souscrire au service vélo pour un autre voyageur que vous.") : null}>
						  <input
							  type="radio"
							  class="peer sr-only"
							  name="passenger"
							  disabled={containCykleo()}
							  value={child.rcuId}
							  onChange={() => setPassenger(child.rcuId)}
							  checked={passenger() === child.rcuId}
							  required
							  id={`child-${child.rcuId}`}
						  />

						  <label
							  for={`child-${child.rcuId}`}
							  class="flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 p-6 text-center focus:border-primary/50 peer-checked:border-primary peer-disabled:opacity-50 peer-disabled:bg-gray-100 peer-disabled:cursor-not-allowed"
						  >
							<div
								class="flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-gray-400 text-white">
							  <Show
								  when={photo()}
								  fallback={<Icon path={user} class="h-8"/>}
							  >
								<img
									src={photo()}
									class="h-full w-full object-cover object-center"
								/>
							  </Show>
							</div>

							<span class="mt-6 font-semibold">
                        {child.firstName} {child.lastName}
                      </span>

							<span class="mt-2 text-sm text-gray-500">
                        né(e) le {formatDate(child.birthDate)}
                      </span>

							{/* <Button
                        variant="secondary"
                        class="mx-auto mt-2"
                        onClick={[removePairing, child.thalesCustomerId]}
                      >
                        Supprimer
                      </Button> */}
						  </label>
						</li>
					);
				  }}
				</For>
				<li onClick={() => containCykleo() ? setError("Vous ne pouvez pas souscrire au service vélo pour un autre voyageur que vous.") : null}>
				  <input
					  type="radio"
					  class="peer sr-only"
					  name="passenger"
					  disabled={containCykleo()}
					  onChange={() => setPassenger("new")}
					  value="new"
					  checked={passenger() === "new"}
					  required
					  id="new"
				  />

				  <label
					  for="new"
					  class="flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 px-4 pt-6 text-center focus:border-primary/50 peer-checked:border-primary peer-disabled:opacity-50 peer-disabled:bg-gray-100 peer-disabled:cursor-not-allowed"
				  >
					<div
						class="flex h-28 w-28 items-center justify-center overflow-hidden rounded-full bg-blue-100 text-white">
					  <div
						  class="flex text-5xl h-12 w-12 items-center justify-center overflow-hidden rounded-full bg-primary text-white">
						+
					  </div>
					</div>
					<Button class="relative -top-6 my-2" variant="grey">
					  Ajouter un voyageur
					</Button>
				  </label>
				</li>
			  </ul>
			</section>

			<Show when={error()}>
			  <p class="mx-auto mt-4 w-full whitespace-pre-wrap rounded-lg border border-red-700 bg-red-50 p-4 text-center text-sm font-semibold text-red-700">
				{error()}
			  </p>
			</Show>

			<Button
				loading={loading()}
				variant="secondary"
				type="submit"
				class="ml-auto mt-4"
			>
			  suivant
			</Button>
		  </form>
		</Show>

		<Modal
			title={showPassengerModalTitle()}
			open={showPassengerModal()}
			onClose={handleClose}
		>
		  <PairingForm onSubmit={handleSearchResultForm} cykleoContext={isCykleo()} cykleoOption={hasCykleoOption()}
					   showCreationForm
					   onlyCreationForm={!svd.found || containCykleo()}
					   onlyParent={!svd.found || containCykleo()}
					   passenger={passenger()}
					   passengerInfo={passengerInfo()}
		  />
		</Modal>
		<Modal
			title={modalTitle()}
			open={showPairingModal()}
			onClose={handleFoundClose}
			zIndex={50}
		>
		  <Found onSubmit={handleSearchResultForm} svdCheckInfo={svd} isMainAccount={isMainAccount()}
				 tryptiqueForm={tryptiqueForm} passengerRcuId={passenger()}></Found>
		  <button class="underline mx-auto block mt-4" type="reset" onClick={[setShowPairingModal, false]}>
			Annuler
		  </button>
		</Modal>
	  </>
  );
}

export default function AssociatePassenger() {
  const go = useNavigate();
  const [sdk] = useSdk();
  const [auth] = useAuth();
  const [tunnel, tunnelActions] = useTunnel();
  onMount(() => {
	dispatchGAPage('traveler-add', auth.user);
  });
  const [children, childrenActions] = createResource(() => {
	return sdk.customer.children(false).then((children) => {
	  dispatchGAEvent('view_traveler_info', null, auth.user);
	  return children;
	});
  });
  const [error, setError] = createSignal('');

  async function validatePassengerProfiles(
	  passengerId: string,
	  _children = children(),
  ) {
	const neededProfiles = tunnel.product.profiles;
	const passenger =
		passengerId === auth.user.rcuId
			? auth.user
			: _children.find(
				(children) => children.rcuId === passengerId,
			);
	if (!passenger) {
	  return false;
	}
	// If the user doesn't have cards then he can potentially order the profile
	// with its new card
	const hasAtLeastOneElligibleProfile =
		neededProfiles?.some((profile) => {
		  if (!passenger) {
			return false;
		  }
		  const hasExplicitProfile = passenger.profiles?.some(
			  (passengerProfile) => {
				return passengerProfile.profileId === String(profile);
			  },
		  );
		  if (hasExplicitProfile) {
			return true;
		  }
		  const profileAsString = String(profile);
		  const isSolidaire = profileAsString.length === 6;
		  if (!isSolidaire) {
			return false;
		  }
		  const explicitProfile = String(profileAsString.slice(0, 3));
		  const implicitProfile = String(profileAsString.slice(3));
		  const hasExplicitProfileForSolidaire = passenger.profiles.some(
			  (passengerProfile) => passengerProfile.profileId === explicitProfile,
		  );
		  const hasImplicitProfileForSolidaire = passenger.profiles.some(
			  (passengerProfile) => passengerProfile.profileId === implicitProfile,
		  );
		  return hasExplicitProfileForSolidaire && hasImplicitProfileForSolidaire;
		}) ?? true;
	if (!hasAtLeastOneElligibleProfile && !tunnel.profile?.isSaleable) {
	  if (tunnel.profile?.externalLink) {
		window.open(tunnel.profile.externalLink, '_blank', 'noopener');
	  }
	  setError("Vous n'êtes pas éligible pour cette offre");
	  return false;
	}
	if (!hasAtLeastOneElligibleProfile) {
	  tunnelActions.set({
		needsAdditionalData: true,
		needsToOrderProfile: true,
	  });
	} else {
	  if (
		  tunnel.needsToValidateTemporalValidity ||
		  tunnel.product.isOrganizationLink
	  ) {
		tunnelActions.set({needsToOrderProfile: false});
	  } else {
		tunnelActions.set({
		  needsAdditionalData: false,
		  needsToOrderProfile: false,
		});
	  }
	}
	return true;
  }

  async function handleSubmit(userId: string) {
	const children = await sdk.customer.children();
	childrenActions.mutate(children);
	const canGoNext = await validatePassengerProfiles(userId);
	if (!canGoNext) {
	  return;
	}

	const passenger = findPassenger(userId, auth, children);

	tunnelActions.set('passenger', passenger.thalesCustomerId);
	tunnelActions.set('passengerRcuId', passenger.rcuId);
	tunnelActions.set('passengerInfos', passenger)

	return go(`/associate/card`);
  }

  return (
	  <PassengerChoice
		  parentChildrenActions={childrenActions}
		  parentChildren={children}
		  error={error()}
	  />
  );
}
